.seoContent {
  div {
    @media (--tablet-l) {
      display: flex;
      gap: 80px;
    }

    & h2 {
      text-wrap: nowrap;
    }
  }
}
